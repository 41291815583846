import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import Modal from '../../../../common/Modal/Modal.container';
import StyledForm from '../../../../common/Form/Form.styled';
import StyledButton from '../../../../common/Form/Button/Button.styled';
import StyledLabel from '../../../../common/Form/Label/Label.styled';
import TextInput from '../../../../common/Form/TextInput/TextInput.component';

const EquipmentInstance = ({ handleSubmit, toggleLabelModal, intl }) => (
  <Modal onClose={toggleLabelModal}>
    <StyledForm onSubmit={ handleSubmit }>
      <h2>
        <FormattedMessage id="equipment.instance.form.labelIdentifier" />
      </h2>

      <p>
        <FormattedMessage id="equipment.instance.form.labelIdentifier_description" />
      </p>

      <div>
        <StyledLabel htmlFor="labelIdentifier">
          <FormattedMessage id="equipment.instance.form.labelIdentifier" />
        </StyledLabel>

        <Field
          id="labelIdentifier"
          name="labelIdentifier"
          component={TextInput}
          type="text"
          placeholder={intl.formatMessage({id:'equipment.instance.form.labelIdentifier'})}
        />
      </div>

      <div className="buttons">
        <StyledButton 
          $discrete 
          type="reset" 
          onClick={ toggleLabelModal }
        >
          <FormattedMessage id="forms.undo" />
        </StyledButton>
        
        <StyledButton>
          <FormattedMessage id="forms.save" />
        </StyledButton>
      </div>
    </StyledForm>
  </Modal>
);

EquipmentInstance.propTypes = {
  handleSubmit: PropTypes.func,
  toggleLabelModal: PropTypes.func,
  intl: PropTypes.object,
};

export default EquipmentInstance;
