import { LOCATIONS_TYPES } from '../../shared/config/locations';

export const table = {
  name: 'locations',
  columns: [
    'name',
    'visitCity',
    'type',
    'reservationEnabled',
    'icon'
  ],
  disabledColumns: [
    'icon',
  ]
};

export const filterReservationEnable = [
  {
    label: 'all',
    reservationEnabled: 'all'
  },
  {
    label: 'enabled',
    reservationEnabled: 'enabled'
  },
  {
    label: 'enabledAdmin',
    reservationEnabled: 'enabledAdmin'
  },
  {
    label: 'disabled',
    reservationEnabled: 'disabled'
  }
];

export const filterDisabledLocations = [
  {
    label: 'unarchived',
    disabled: '0'
  },
  {
    label: 'archived',
    disabled: '1'
  },
  {
    label: 'all',
    disabled: 'all'
  }
];

export const locationTypeFilterOptions = LOCATIONS_TYPES;

export const defaultLocationFilter = {
  type: 'franchise,network,mapEntry,selfService',
  reservationEnabled: 'all',
  disabled: '0',
};

export const google_maps_options = {
  fullscreenControl: false,
}

export const google_maps_config = {
  key: 'AIzaSyATxhzr4o9OATBnpR8DvLuf-9l46V5jvH8',
  language: 'no',
  libraries: 'places',
};

export const selectedZoom = 12;

export const google_maps_defaults = {
  defaultZoom: 3,
  defaultCenter: {
    lat: 65.561796,
    lng: 16.203321
  }
};
