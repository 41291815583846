export const table = {
  name: 'users',
  columns: [
    'firstName',
    'lastName',
    // 'organizationName',
    'mobilePhone',
    'icon'
  ],
  disabledColumns: [
    'icon'
  ]
};

export const banFilterOptions = [
  {
    label: 'all',
    banned: 'all'
  },
  {
    label: 'accepted',
    banned: '0'
  },
  {
    label: 'banned',
    banned: '1'
  }
];

export const defaultBanFilter = {
  banned: 'all'
};
