import React from 'react';
import PropTypes from 'prop-types';

import CloseButton from './CloseButton/CloseButton.styled';
import { StyledNotification } from './Notification.styled';

const Notification = ({ item, clickClose }) => {
  let message;
  if (typeof item === 'string') message = <div>{item}</div>;

  if (item) {
    if (item.response && item.response.body) {
      message = (
        <>
          {!item.response.body.message ? null : (
            <div>{item.response.body.message}</div>
          )}
          {!item.response.body.errorData ? null : (
            <div>
              {Object.keys(item.response.body.errorData).map((key, index) => (
                <div key={index}>
                  <strong>{key}:</strong> {item.response.body.errorData[key]}
                </div>
              ))}
            </div>
          )}
        </>
      )
    } else if (item.notificationText) {
      message = <div>{item.notificationText}</div>
    }
  }

  return (
    <StyledNotification $notificationType={Boolean(item.notificationText)}>
      {message || ''}

      <CloseButton
        $notificationType={Boolean(item.notificationText)}
        href="#"
        onClick={e => {
          e.preventDefault();
          clickClose();
        }}
      />
    </StyledNotification>
  );
};

Notification.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  clickClose: PropTypes.func
};

export default Notification;
