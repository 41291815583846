import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const HeaderLink = styled(NavLink)`
  display: block;
  color: ${props => props.theme.colorWhite};
  transition: all 0.1s linear;
  padding: 10px 16px;
  text-decoration: none;

  &.active,
  &:hover {
    color: ${props => props.theme.colorWhite};
    background-color: ${props => props.theme.colorGrayDarker};
  }

  .counter {
    float: right;
    display: inline-block;
    border: 1px solid ${props => props.theme.colorWhite};
    color: ${props => props.theme.colorWhite};
    font-size: 10px;
    border-radius: 4px;
    padding: 2px 4px;
  }
`;

export default HeaderLink;