import { omit } from 'lodash';
import { change } from 'redux-form';
import { replace, push } from '@lagunovsky/redux-react-router';

import { createLogic, createLogicWithApi, createLogicWithRouteChecks } from '../../../../shared/logicCreators';
import { table as tableConfig, tableVariants as tableVariantsConfig } from '../../../config/equipment';
import { URL_CHANGE } from '../../../../shared/components/App/App.actions';
import { 
  SCANNER_ACTION_EQUIPMENT_OVERVIEW, 
  SCANNER_ACTION_EQUIPMENT_LABEL, 
  equipmentScanCompleted 
} from '../../common/PubSub/PubSub.actions';
import { 
  formName as equipmentInstancelabelFormName
 } from '../../pages/Equipment/EquipmentInstance/EquipmentInstanceLabelForm/EquipmentInstanceLabelForm.container';
import {
  equipment as apiConfig,
  variants as variantsApiConfig,
  instances as instancesApiConfig,
  images as imagesApiConfig,
  locations as locationsApiConfig
} from '../../../config/api';
import {
  FETCH_EQUIPMENT,
  FETCH_EQUIPMENT_SUCCESS,
  CANCEL_FETCH_EQUIPMENT,
  FETCH_EQUIPMENT_BY_ID,
  CANCEL_FETCH_BY_ID_EQUIPMENT,
  SAVE_EQUIPMENT_BY_ID,
  CANCEL_SAVE_EQUIPMENT_BY_ID,
  SAVE_EQUIPMENT_BY_ID_SUCCESS,
  REMOVE_EQIPMENT_BY_ID,
  CANCEL_REMOVE_EQIPMENT_BY_ID,
  REMOVE_EQIPMENT_BY_ID_SUCCESS,
  SAVE_EQUIPMENT_VARIANT,
  CANCEL_SAVE_EQUIPMENT_VARIANT,
  SAVE_EQUIPMENT_VARIANT_SUCCESS,
  SAVE_EQUIPMENT_INSTANCES,
  CANCEL_SAVE_EQUIPMENT_INSTANCES,
  SAVE_EQUIPMENT_INSTANCES_SUCCESS,
  FETCH_EQUIPMENT_VARIANT,
  CANCEL_FETCH_EQUIPMENT_VARIANT,
  FETCH_EQUIPMENT_INSTANCE,
  SAVE_EQUIPMENT_INSTANCE,
  CANCEL_SAVE_EQUIPMENT_INSTANCE,
  SAVE_EQUIPMENT_INSTANCE_SUCCESS,
  CANCEL_REMOVE_EQUIPMENT_INSTANCE,
  REMOVE_EQUIPMENT_INSTANCE,
  SAVE_EQUIPMENT_IMAGE,
  CANCEL_SAVE_EQUIPMENT_IMAGE,
  DELETE_EQUIPMENT_IMAGE,
  CANCEL_DELETE_EQUIPMENT_IMAGE,
  PATCH_EQUIPMENT_IMAGES,
  CANCEL_PATCH_EQUIPMENT_IMAGES,
  FETCH_EQUIPMENT_VARIANTS,
  CANCEL_FETCH_EQUIPMENT_VARIANTS,
  FETCH_EQUIPMENT_VARIANTS_SUCCESS,
  REMOVE_EQUIPMENT_VARIANT,
  CANCEL_REMOVE_EQUIPMENT_VARIANT,
  REMOVE_EQUIPMENT_VARIANT_SUCCESS,
  saveEquipmentImageSuccess,
  saveEquipmentImageError,
  deleteEquipmentImageSuccess,
  deleteEquipmentImageError,
  patchEquipmentImagesSuccess,
  patchEquipmentImagesError,
  fetchEquipmentSuccess,
  fetchEquipmentError,
  selectEquipmentTable,
  fetchEquipmentById,
  fetchEquipmentByIdSuccess,
  fetchEquipmentByIdError,
  saveEquipmentByIdSuccess,
  saveEquipmentByIdError,
  removeEquipmentByIdSuccess,
  removeEquipmentByIdError,
  saveEquipmentVariantSuccess,
  saveEquipmentVariantError,
  removeEquipmentVariantSuccess,
  removeEquipmentVariantError,
  saveEquipmentInstancesSuccess,
  saveEquipmentInstancesError,
  fetchEquipmentVariant,
  fetchEquipmentVariantSuccess,
  fetchEquipmentVariantError,
  fetchEquipmentVariants,
  fetchEquipmentVariantsSuccess,
  fetchEquipmentVariantsError,
  fetchEquipmentInstanceSuccess,
  fetchEquipmentInstance,
  fetchEquipmentInstanceError,
  saveEquipmentInstanceSuccess,
  saveEquipmentInstanceError,
  removeEquipmentInstanceSuccess,
  removeEquipmentInstanceError,
  moveNextStep,
  DOWNLOAD_EQUIPMENT,
} from '../../../../shared/components/Equipment/Equipment.actions';

const checkEquipmentRouteLogic = createLogicWithRouteChecks({
  type: URL_CHANGE,
  process: ({ 
    checkUnselectedIdParam, 
    checkInitSelectWithLocation, 
    getUrl, 
    checkInitLocation, 
    checkUnselectedVariantParam, 
    checkUnselectedInstanceParam 
  }, dispatch, done) => {
    const locationId = checkInitLocation();

    if (locationId) {
      const selectId = checkInitSelectWithLocation('equipment');

      if (selectId) {
        dispatch(replace(`${getUrl('equipment')}/${locationId}/${selectId}${window.location.search}`));

      } else {
        const newIdParam = checkUnselectedIdParam('equipment', locationId);

        if (newIdParam !== false) {
          dispatch(selectEquipmentTable(newIdParam));
          dispatch(fetchEquipmentById({
            id: newIdParam,
            location: locationId
          }));
        }

        const variantId = checkUnselectedVariantParam('equipment', locationId);

        if (variantId) {
          dispatch(fetchEquipmentVariant({
            id: variantId,
            equipmentId: newIdParam
          }));
        }

        const instanceId = checkUnselectedInstanceParam('equipment', locationId);

        if (instanceId) {
          dispatch(fetchEquipmentInstance({
            id: instanceId,
            variantId
          }));
        }
      }
    }
    done();
  }
});

const fetchEquipmentLogic = createLogicWithApi({
  type: FETCH_EQUIPMENT,
  cancelType: CANCEL_FETCH_EQUIPMENT,
  latest: true,
  process: ({ Api, getState, action }, dispatch, done) => {
    const state = getState(),
      location = action.location ?
        action.location :
        state.table.currentLocation || state.router.location.pathname.split('/')[2] || null;

    return Api({
      method: 'get',
      path: `${locationsApiConfig.path}/${location}${apiConfig.path}`,
      handle: r => {
        const state = getState();
        const sort = action.sort ? action.sort : state.table.items[tableConfig.name].sort;
        const queryFields = action.queryFields;
        const desc = typeof action.desc !== 'undefined' ? action.desc : state.table.items[tableConfig.name].desc;
        const filters = typeof action.filters !== 'undefined' ? action.filters : state.table.filters;
        const search = typeof action.search !== 'undefined' ? action.search : state.table.search;
        const limit =  typeof action.limit !== 'undefined' ? action.limit : state.table.items[tableConfig.name].limit;
        const offset =  typeof action.offset !== 'undefined' ? action.offset : state.table.items[tableConfig.name].offset;

        if (offset) {
          r.query({'offset': offset});
        }

        if (queryFields) {
          r.query({'queryFields': queryFields});
        }

        r
          .query({'limit': limit})
          .query({'filters': filters})
          .query({'query': search})
          .query({'order[field]': sort})
          .query({'order[dir]': desc ? 'desc' : 'asc'});

        return r;
      }
    })
    .then(resp => {
      dispatch(fetchEquipmentSuccess(resp));
      done();
    })
    .catch(err => {
      dispatch(fetchEquipmentError(err));
      done();
    })
  }
});

const checkFetchedEquipmentSelectionLogic = createLogicWithRouteChecks({
  type: FETCH_EQUIPMENT_SUCCESS,
  process: ({ checkInitSelectWithLocation, checkInitLocation, checkUnselectedIdParam, getUrl, state }, dispatch, done) => {
    const locationId = checkInitLocation();
    const selectId = checkInitSelectWithLocation('equipment');
    const {
      equipment:{ items, selected },
      table:{ search }
    } = state;

    if (search && search !== '' && items && items.length === 1) {
      if (!selected || !selected.id || String(items[0].id) !== String(selected.id)) {
        dispatch(push(`${getUrl('equipment')}/${locationId}/${items[0].id}${window.location.search}`));

      } else if (selected && selected.id && String(items[0].id) === String(selected.id)) {
        dispatch(fetchEquipmentVariants());
      }

    } else if (selectId) {
      dispatch(replace(`${getUrl('equipment')}/${locationId}/${selectId}${window.location.search}`));

    } else {
      const newIdParam = checkUnselectedIdParam('equipment', locationId);

      if (newIdParam !== false) {
        dispatch(selectEquipmentTable(newIdParam));
        dispatch(fetchEquipmentById({
          id: newIdParam,
          location: locationId
        }));
      }
    }

    done();
  }
});

const fetchEquipmentLogicById = createLogicWithApi({
  type: FETCH_EQUIPMENT_BY_ID,
  cancelType: CANCEL_FETCH_BY_ID_EQUIPMENT,
  latest: true,
  process: ({ action, Api, getState }, dispatch, done) => {
    const state = getState();
    const location = action.location ? action.location : state.table.currentLocation;

    return Api({
      method: 'get',
      path: `${locationsApiConfig.path}/${location}${apiConfig.path}/${action.id}`,
    })
      .then(resp => {
        dispatch(fetchEquipmentByIdSuccess(resp));
        done();
      })
      .catch(err => {
        dispatch(fetchEquipmentByIdError(err));
        done();
      })
  }
});

const saveEquipmentByIdLogic = createLogicWithApi({
  type: SAVE_EQUIPMENT_BY_ID,
  cancelType: CANCEL_SAVE_EQUIPMENT_BY_ID,
  process: ({ action, Api, getState }, dispatch, done) => {
    const state = getState();
    const method = action.payload.id ? 'PATCH' : 'POST';
    const path = `${locationsApiConfig.path}/${state.table.currentLocation}${apiConfig.path}${action.payload.id ? `/${action.payload.id}` : ''}`;

    return Api({
      method,
      path,
      handle: r => {
        return r.send(
          action.payload.id ?
            {
              attributes: action.payload,
              type: 'equipment',
              id: action.payload.id ? action.payload.id : null
            } : { attributes: action.payload}
        );
      }
    })
    .then(resp => {
      resp.data.attributes = {
        ...resp.data.attributes,
        ...action.payload
      };

      dispatch(saveEquipmentByIdSuccess(resp, action.nextStep, action.payload.id));
      done();
    })
    .catch(err => {
      dispatch(saveEquipmentByIdError(err));
      done();
    })
  }
});

const saveEquipmentByIdSuccessLogic = createLogicWithRouteChecks({
  type: SAVE_EQUIPMENT_BY_ID_SUCCESS,
  process: ({ getUrl, state, action}, dispatch, done) => {
    if (action.nextStep === 0) {
      dispatch(push(`${getUrl('equipment')}/${state.table.currentLocation}/${action.payload.data.id}${state.locale.actions.create.path}/1${window.location.search}`));

    } else if (action.nextStep) {
      dispatch(push(`${getUrl('equipment')}/${state.table.currentLocation}/${action.payload.data.id}${state.locale.actions.create.path}/${action.nextStep}${window.location.search}`));
      dispatch(moveNextStep());

    } else {
      dispatch(push(`${getUrl('equipment')}/${state.table.currentLocation}/${action.payload.data.id}${window.location.search}`));
    }

    done();
  }
});

const removeEquipmentByIdLogic = createLogicWithApi({
  type: REMOVE_EQIPMENT_BY_ID,
  cancelType: CANCEL_REMOVE_EQIPMENT_BY_ID,
  latest: true,
  process: ({ action, Api, getState }, dispatch, done) => {
    const state = getState();
    const locationId = state.table.currentLocation;

    return Api({
      method: 'DELETE',
      path: `${locationsApiConfig.path}/${locationId}${apiConfig.path}/${action.id}`
    })
      .then(() => {
        dispatch(removeEquipmentByIdSuccess({id: action.id}));
        done();
      })
      .catch(err => {
        dispatch(removeEquipmentByIdError(err));
        done();
      })
  }
});

const checkRemoveEquipmentByIdLogic = createLogicWithRouteChecks({
  type: REMOVE_EQIPMENT_BY_ID_SUCCESS,
  process: ({ getState }, dispatch, done) => {
    const state = getState();
    const location = state.table.currentLocation || state.router.location.pathname.split('/')[2] || null;

    dispatch(
      push(`${apiConfig.path}/${location}${window.location.search}`)
    );

    done();
  }
});

const saveEquipmentImageLogic = createLogicWithApi({
  type: SAVE_EQUIPMENT_IMAGE,
  cancelType: CANCEL_SAVE_EQUIPMENT_IMAGE,
  process: ({ action, Api }, dispatch, done) => {
    const method = action.payload.id ? 'PATCH' : 'POST',
          path = `${apiConfig.path}/${action.equipment}${imagesApiConfig.path}${action.payload.id ? `/${action.payload.id}` : ''}`;
    return Api({
      method: method,
      path: path,
      handle: r => {
        if (action.payload.imageFile) {
          r
            .field('data[attributes][image][state]', 'published')
            .attach('data[attributes][image][imageFile]',action.payload.imageFile);
        } else {
          r.send({
            attributes: { image: omit(action.payload, ['id']) },
            type: 'equipment_image',
            id: action.payload.id ? action.payload.id : null
          });
        }
        return r;
      }
    })
      .then(resp => {
        dispatch(saveEquipmentImageSuccess(action.equipment, resp));
        done();
      })
      .catch(err => {
        dispatch(saveEquipmentImageError(err));
        done();
      });
  }
});

const deleteEquipmentImageLogic = createLogicWithApi({
  type: DELETE_EQUIPMENT_IMAGE,
  cancelType: CANCEL_DELETE_EQUIPMENT_IMAGE,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'DELETE',
      path: `${apiConfig.path}/${action.equipment}${imagesApiConfig.path}/${action.image}`
    })
      .then(() => {
        dispatch(deleteEquipmentImageSuccess(action.equipment, action.image));
        done();
      })
      .catch(err => {
        dispatch(deleteEquipmentImageError(err));
        done();
      });
  }
});

const patchEquipmentImagesLogic = createLogicWithApi({
  type: PATCH_EQUIPMENT_IMAGES,
  cancelType: CANCEL_PATCH_EQUIPMENT_IMAGES,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'patch',
      path: `${apiConfig.path}/${action.equipment}${imagesApiConfig.path}`,
      handle: r => {
        return r.send(action.images);
      }
    })
      .then(() => {
        dispatch(patchEquipmentImagesSuccess(action.equipment, action.images));
        done();
      })
      .catch(err => {
        dispatch(patchEquipmentImagesError(err));
        done();
      });
  }
});

/**
* Variant Logic
*/

const saveEquipmentVariantLogic = createLogicWithApi({
  type: SAVE_EQUIPMENT_VARIANT,
  cancelType: CANCEL_SAVE_EQUIPMENT_VARIANT,
  process: ({ action, Api, getState}, dispatch, done) => {
    const state = getState();
    const equipmentId = action.payload.equipment || state.equipment.selected.id;
    const method = action.payload.id ? 'PATCH' : 'POST';
    const path = `${apiConfig.path}/${equipmentId}${variantsApiConfig.path}${action.payload.id ? `/${action.payload.id}` : ''}`;

    return Api({
      method,
      path,
      handle: r => {
        return r.send(
          action.payload.id ?
            {
              attributes: action.payload,
              type: 'equipment',
              id: action.payload.id ? action.payload.id : null
            } : { attributes: action.payload}
        );
      }
    })
    .then(resp => {
      resp.data.attributes = {
        ...resp.data.attributes,
        ...action.payload
      };

      dispatch(saveEquipmentVariantSuccess(resp, action.nextStep, action.payload.id));

      if (!action.payload.id && String(resp.data.attributes.equipment) === String(equipmentId)) {
        dispatch(fetchEquipmentVariants());
      }

      done();
    })
    .catch(err => {
      dispatch(saveEquipmentVariantError(err));
      done();
    })
  }
});

const saveEquipmentVariantSuccessLogic = createLogicWithRouteChecks({
  type: SAVE_EQUIPMENT_VARIANT_SUCCESS,
  process: ({ getUrl, state, action }, dispatch, done) => {
    const equipmentId = state.equipment.selected.id;
    const locationId = state.table.currentLocation;

    if (action.nextStep === 0) {
      dispatch(push(`${getUrl('equipment')}/${locationId}/${equipmentId}/${action.payload.data.id}/1${state.locale.actions.create.path}/1${window.location.search}`));

    } else if (action.nextStep) {
      dispatch(push(`${getUrl('equipment')}/${locationId}/${equipmentId}/${action.payload.data.id}${state.locale.actions.create.path}/${action.nextStep}${window.location.search}`));
      dispatch(moveNextStep());

    } else {
      dispatch(push(`${getUrl('equipment')}/${locationId}/${equipmentId}/${action.payload.data.id}${window.location.search}`));
    }

    dispatch(fetchEquipmentVariant({
      id: action.payload.data.id
    }));

    done();
  }
});

const fetchVariantLogic = createLogicWithApi({
  type: FETCH_EQUIPMENT_VARIANT,
  cancelType: CANCEL_FETCH_EQUIPMENT_VARIANT,
  latest: true,
  process: ({ action, Api, getState }, dispatch, done) => {
    const state = getState();
    const equipmentId = action.equipmentId ? action.equipmentId : state.equipment.selected.id;
    const id = action.id ? action.id : state.equipment.variants.selected.id;

    return Api({
      method: 'get',
      path: `${apiConfig.path}/${equipmentId}${variantsApiConfig.path}/${id}`
    })
      .then(resp => {
        dispatch(fetchEquipmentVariantSuccess(resp));
        done();
      })
      .catch(err => {
        dispatch(fetchEquipmentVariantError(err));
        done();
      })
  }
});

const fetchVariantsLogic = createLogicWithApi({
  type: FETCH_EQUIPMENT_VARIANTS,
  cancelType: CANCEL_FETCH_EQUIPMENT_VARIANTS,
  latest: true,
  process: ({Api, getState, action}, dispatch, done) => {
    const state = getState();
    const equipmentId = action.id 
      ? action.id 
      : (
        state.equipment.selected.id 
        || state.router.location.pathname.split('/')[3] 
        || null
      );

    return Api({
      method: 'GET',
      path: `${apiConfig.path}/${equipmentId}${variantsApiConfig.path}`,
      handle: r => {
        const state = getState();
        const sort = action.sort ? action.sort : state.equipment.variants.meta.sort;
        const desc = typeof action.desc !== 'undefined' ? action.desc : state.equipment.variants.meta.desc;
        const search = typeof action.search !== 'undefined' ? action.search : state.table.search;
        const queryFields = action.queryFields;
        const items = state.equipment.items;

        let limit =  typeof action.limit !== 'undefined' 
          ? action.limit 
          : state.table.items?.[tableVariantsConfig.name]?.limit;

        let offset =  typeof action.offset !== 'undefined' 
          ? action.offset 
          : state.table.items?.[tableVariantsConfig.name]?.offset;

        if (search && search !== '' && items && items.length === 1) {
          limit = 0;
          offset = 0;
        }

        if (offset) {
          r.query({'offset': offset});
        }

        if (queryFields) {
          r.query({'queryFields': queryFields});
        }

        if (state.table.filters.instanceStatus) {
          r.query({'filters[instanceStatus]': state.table.filters.instanceStatus})
        }

        r
          .query({'limit': limit})
          .query({'filters[state]': 'published,temporary'})
          .query({'identifierQuery': search})
          .query({'order[field]': sort})
          .query({'order[dir]': desc ? 'desc' : 'asc'});

        return r;
      }
    })
      .then((resp) => {
        dispatch(fetchEquipmentVariantsSuccess(resp));
        done();
      })
      .catch(err => {
        dispatch(fetchEquipmentVariantsError(err));
        done();
      })
  }
});

export const searchedVariantHelper = ({ identifier, labelIdentifier }) => [ identifier.toUpperCase(), labelIdentifier ];

const fetchVariantsSuccessLogic = createLogicWithRouteChecks({
  type: FETCH_EQUIPMENT_VARIANTS_SUCCESS,
  latest: true,
  process: ({ getState, getUrl, checkInitLocation }, dispatch, done) => {
    const state = getState(),
          locationId = checkInitLocation(),
          search = state.table.search,
          items = state.equipment.items,
          equipment = state.equipment.selected,
          variants = state.equipment.variants.items;
    if (search && search !== '' && items && items.length === 1 && variants && variants.length) {
      const searchedVariant = variants.find(v => v.instances.some(i => searchedVariantHelper(i).includes(search.toUpperCase())));
      if (searchedVariant) {
        dispatch(push(`${getUrl('equipment')}/${locationId}/${equipment.id}/${searchedVariant.id}${window.location.search}`));
      }
    }
    done();
  }
});

const removeEquipmentVariantLogic = createLogicWithApi({
  type: REMOVE_EQUIPMENT_VARIANT,
  cancelType: CANCEL_REMOVE_EQUIPMENT_VARIANT,
  latest: true,
  process: ({ action, Api, getState }, dispatch, done) => {
    const state = getState(),
      equipmentId = state.equipment.selected.id || state.router.location.pathname.split('/')[3] || null;

    return Api({
      method: 'DELETE',
      path: `${apiConfig.path}/${equipmentId}${variantsApiConfig.path}/${action.id}`,
    })
      .then(() => {
        dispatch(removeEquipmentVariantSuccess({id: action.id, equipment: action.equipment}));
        done();
      })
      .catch(err => {
        dispatch(removeEquipmentVariantError(err));
        done();
      })
  }
});

const checkRemoveEquipmentVariantLogic = createLogic({
  type: REMOVE_EQUIPMENT_VARIANT_SUCCESS,
  transform: ({ getState, action, storeDispatch }, next) => {
    var state = getState();
    var equipment = action.equipment;
    var location = state.table.currentLocation || state.router.location.pathname.split('/')[2] || null;
    storeDispatch(
      push(`${apiConfig.path}/${location}/${equipment}${window.location.search}`)
    );
    next(action);
  }
});

/**
 * Instances Logic
 */

const fetchEquipmentInstanceLogic = createLogicWithApi({
  type: FETCH_EQUIPMENT_INSTANCE,
  cancelType: CANCEL_FETCH_EQUIPMENT,
  latest: true,
  process: ({ action, Api, getState }, dispatch, done) => {
    const state = getState();
    const variantId = action.variantId ? action.variantId : state.equipment?.variants?.selected ? state.equipment.variants.selected.id : null;
    const id = action.id ? action.id : state.equipment?.variants?.instances?.selected ? state.equipment.variants.instances.selected.id : null;

    if (!variantId || !id) {
      done();
      return;
    }

    return Api({
      method: 'GET',
      path: `${variantsApiConfig.path}/${variantId}${instancesApiConfig.path}/${id}`
    })
    .then(resp => {
      dispatch(fetchEquipmentInstanceSuccess(resp));
      done();
    })
    .catch(err => {
      dispatch(fetchEquipmentInstanceError(err));
      done();
    })
  }
});

const saveEquipmentInstancesLogic = createLogicWithApi({
  type: SAVE_EQUIPMENT_INSTANCES,
  cancelType: CANCEL_SAVE_EQUIPMENT_INSTANCES,
  process: ({ action, Api, getState }, dispatch, done) => {
    const state = getState(),
      variantId = state.equipment.variants.selected.id;

    return Api({
      method: 'POST',
      path: `${variantsApiConfig.path}/${variantId}${instancesApiConfig.path}${instancesApiConfig.listPath}`,
      handle: r=> {
        return r.send(
          action.payload.map(identifier => {
            return {
              attributes: {
                state: 'published', // default value
                identifier
              }
            }
          })
        )
      }
    })
    .then(() => {
      dispatch(saveEquipmentInstancesSuccess());
      done();
    })
    .catch(err => {
      dispatch(saveEquipmentInstancesError(err));
      done();
    })
  }
});

const saveEquipmentInstancesSuccessLogic = createLogicWithRouteChecks({
  type: [SAVE_EQUIPMENT_INSTANCES_SUCCESS, SAVE_EQUIPMENT_INSTANCE_SUCCESS],
  process: ({ getUrl, state }, dispatch, done) => {
    const locationId = state.table.currentLocation;
    const equipmentId = state.equipment.selected.id;
    const variantId = state.equipment.variants.selected.id;

    dispatch(fetchEquipmentVariant({ id: variantId }));
    dispatch(push(`${getUrl('equipment')}/${locationId}/${equipmentId}/${variantId}${window.location.search}`));
    done();
  }
});

const saveEquipmentInstanceLogic = createLogicWithApi({
  type: SAVE_EQUIPMENT_INSTANCE,
  cancelType: CANCEL_SAVE_EQUIPMENT_INSTANCE,
  process: ({ action, Api, getState }, dispatch, done) => {
    const state = getState();
    const variantId = action.payload.variantId ? action.payload.variantId : state.equipment.variants.selected.id;
    const id = action.payload.id ? action.payload.id : state.equipment.variants.instances.selected.id;

    return Api({
      method: action.payload.id ? 'PATCH' : 'POST',
      path: `${variantsApiConfig.path}/${variantId}${instancesApiConfig.path}/${id}`,
      handle: r => {
        return r.send(
          action.payload.id ?
            {
              attributes: action.payload,
              type: 'equipment_instance',
              id: action.payload.id ? action.payload.id : null
            } : { attributes: action.payload}
        );
      }
    })
    .then(resp => {
      resp.data.attributes = {
        ...resp.data.attributes,
        ...action.payload,
      };

      dispatch(saveEquipmentInstanceSuccess(resp, action.nextStep));
      done();
    })
    .catch(err => {
      dispatch(saveEquipmentInstanceError(err));
      done();
    })

  }
});

const saveEquipmentInstanceSuccessLogic = createLogicWithRouteChecks({
  type: SAVE_EQUIPMENT_INSTANCE_SUCCESS,
  process: ({ getUrl, state, action }, dispatch, done) => {
    const instanceData = action.payload.data.attributes;
    const locationId = instanceData.location || state.table.currentLocation;
    const equipmentId = instanceData.equipment || state.equipment.selected.id;
    const variantId = instanceData.variant || state.equipment.variants.selected.id;

    if (instanceData.status === 'discarded') {
      dispatch(removeEquipmentInstanceSuccess(equipmentId, variantId, action.payload.data.id));
    }

    if (action.nextStep !== false) {
      dispatch(push(`${getUrl('equipment')}/${locationId}/${equipmentId}/${variantId}${window.location.search}`));
    }

    done();
  }
});

const removeEquipmentInstanceLogic = createLogicWithApi({
  type: REMOVE_EQUIPMENT_INSTANCE,
  cancelType: CANCEL_REMOVE_EQUIPMENT_INSTANCE,
  latest: true,
  process: ({ action, Api, getState }, dispatch, done) => {
    const state = getState();
    const locationId = state.table.currentLocation;
    const equipmentId = state.equipment.selected.id;
    const variantId = state.equipment.variants.selected.id;

    return Api({
      method: 'DELETE',
      path: `${variantsApiConfig.path}/${variantId}${instancesApiConfig.path}/${action.id}`
    })
      .then(() => {
        dispatch(removeEquipmentInstanceSuccess(equipmentId, variantId, action.id));
        dispatch(push(`/equipment/${locationId}/${equipmentId}/${variantId}${window.location.search}`));
        done();
      })
      .catch(err => {
        dispatch(removeEquipmentInstanceError(err));
        done();
      })

  }
});

const scannerActionEquipmentOverviewLogic = createLogicWithRouteChecks({
  type: SCANNER_ACTION_EQUIPMENT_OVERVIEW,
  process: ({ getUrl, state, action }, dispatch, done) => {
    dispatch(push(`${getUrl('equipment')}/${state.table.currentLocation}/${action.payload.data.attributes.equipment}/${action.payload.data.attributes.variant}/${action.payload.data.id}${window.location.search}`));
    dispatch(equipmentScanCompleted());
    done();
  }
});

const scannerActionEquipmentLabelLogic = createLogic({
  type: SCANNER_ACTION_EQUIPMENT_LABEL,
  process: ({ action }, dispatch, done) => {
    dispatch(change(equipmentInstancelabelFormName, 'labelIdentifier', action.payload));
    dispatch(equipmentScanCompleted());
    done();
  }
});

const downloadEquipmentLogic = createLogicWithApi({
  type: DOWNLOAD_EQUIPMENT,
  latest: true,
  process: ({ action, Api, getState }, dispatch, done) => {
    const state = getState();
    const location = action.location ? action.location : state.table.currentLocation;
    return Api({
      method: 'POST',
      path: `${locationsApiConfig.path}/${location}${apiConfig.path}${apiConfig.exportPath}`,
      handle: r => {
        r
          .responseType('blob')
          .query({'filters': action.dates});
        return r;
      }
    })
      .then(file => {
        const fileUrl = URL.createObjectURL(file);
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        fileLink.download = `utstyrsliste_${action.dates ? `${action.dates.startDate || ''}-${action.dates.endDate || ''}`: ''}.xlsx`;
        document.body.appendChild(fileLink);
        fileLink.click();
        URL.revokeObjectURL(fileUrl);
        fileLink.remove();
        done();
      })
      .catch(err => {
        console.error(err);
        done();
      });
  }
});

const logics = [
  checkEquipmentRouteLogic,
  fetchEquipmentLogic,
  checkFetchedEquipmentSelectionLogic,
  fetchEquipmentLogicById,
  saveEquipmentByIdLogic,
  saveEquipmentByIdSuccessLogic,
  removeEquipmentByIdLogic,
  checkRemoveEquipmentByIdLogic,
  saveEquipmentImageLogic,
  deleteEquipmentImageLogic,
  patchEquipmentImagesLogic,

  fetchVariantsLogic,
  fetchVariantsSuccessLogic,
  fetchVariantLogic,
  saveEquipmentVariantLogic,
  saveEquipmentVariantSuccessLogic,
  removeEquipmentVariantLogic,
  checkRemoveEquipmentVariantLogic,

  saveEquipmentInstancesLogic,
  saveEquipmentInstancesSuccessLogic,
  fetchEquipmentInstanceLogic,
  saveEquipmentInstanceLogic,
  saveEquipmentInstanceSuccessLogic,
  removeEquipmentInstanceLogic,

  scannerActionEquipmentOverviewLogic,
  scannerActionEquipmentLabelLogic,

  downloadEquipmentLogic,
];

export default logics;