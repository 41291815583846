import React from 'react';
import PropTypes from 'prop-types';

const Check = ({ stroke }) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" alt="Ja">
    <path fill="none" stroke={stroke ? stroke : '#000'} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"
      d="M1 4.95l3.057 3.056L8.3 1" />
  </svg>
;

Check.propTypes = {
  stroke: PropTypes.string,
};

export default Check;
