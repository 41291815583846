import styled, { css } from 'styled-components';

const block = css`
  display: block;
  width: 100%;
  height: 40px;
`;

const inline = css`
  display: inline-block;
`;

const primary = css`
  background-color: ${props => props.theme.colorPurple};
  color: ${props => props.theme.colorWhite};

  &:hover,
  &:active {
    background-color: ${props => props.theme.colorPurpleHover};
  }
`;

const secondary = css`
  background-color: ${props => props.theme.colorWhite};
  color: ${props => props.theme.colorPurple};
  border: ${props => props.theme.colorPurple} solid 1px;

  &:hover,
  &:active {
    border-color: ${props => props.theme.colorPurpleHover};
    color: ${props => props.theme.colorPurpleHover};
  }
`;

const disabled = css`
  background-color: ${props => props.theme.colorGrayLight};
  color: ${props => props.theme.colorGray};
  cursor: default;
`;

const discrete = css`
  background-color: transparent;
  color: ${props => props.theme.colorGrayDark};
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
  min-width: 0;

  &:hover,
  &:focus {
    color: ${props => props.theme.colorGray};
  }
`;

const success = css`
  background-color: ${props => props.theme.colorGreenDark};
`;

const danger = css`
  color: ${props => props.theme.colorRed};

  &:hover,
  &:focus {
    color: ${props => props.theme.colorRed};
  }
`;

const smaller = css`
  height: 21px;
  min-width: 62px;
  padding: 0 6px;
`;

const fullWidth = css`
  width: 100%;
`;

const StyledButton = styled.button`
  transition: all 0.1s linear;
  min-width: 72px;
  border: 0;
  border-radius: 2px;
  -webkit-appearance: none;
  padding: 8px 12px 10px;
  box-shadow: 0 2px 6px 0 rgba(54, 60, 76, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.04);
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  max-width: 100%;
  overflow: hidden;
  line-height: 1;
  outline: 0;
  height: 32px;

  ${props => props.disabled ? disabled 
    : props.$discrete ? discrete 
    : props.$secondary ? secondary : primary
  }
  ${props => props.$block ? block : inline}
  ${props => props.$smaller && smaller}
  ${props => props.$fullWidth && fullWidth}
  ${props => props.$success && success}
  ${props => props.$danger && danger}

  & + ${() => StyledButton} {
    margin-left: 16px;
  }
`;

export default StyledButton;
