import styled, { css } from 'styled-components';

export const textInputBase = css`
  background-color: ${props => props.$white ? props.theme.colorWhite : props.theme.colorGrayLightest};
  border: ${props => props.theme.colorGrayLight} solid 1px;
  border-radius: 2px;
  width: 100%;
  line-height: inherit;
  padding: 13px 14px;
  outline: none;

  &:disabled {
    border-style: dashed;
  }

  &[type="time"]::-webkit-clear-button,
  &[type="time"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
  }

  &[type="time"]::-ms-clear {
    display: none;
  }
`;

export const textInputSearch = css`
  ${textInputBase};
  padding-left: 42px;
`;

export const invalid = css`
  border: ${props => props.theme.colorRed} solid 1px;
`;

const empty = css`
  &[type="date"] {
    color: ${props => props.theme.colorGrayDark};
  }
`;

const success = css`
  svg {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 12px;
    top: 12px;
  }
`;

const StyledTextInput = styled.input`
  height: 42px;
  ${props => props.type === 'search' ? textInputSearch : textInputBase };
  ${props => props.$invalid ? invalid : null};
  ${props => !props.value ? empty : null};
`;

export const StyledTextInputWrapper = styled.div`
  ${props => props.$success ? success : null};
`;

export default StyledTextInput;
