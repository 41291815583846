import { createLogicWithApi } from '../../../../shared/logicCreators';
import { locations as apiConfig } from '../../../config/api';
import {
  FETCH_GLOBAL_LOCATIONS,
  CANCEL_FETCH_GLOBAL_LOCATIONS,
  fetchGlobalLocationsSuccess,
  fetchGlobalLocationsError
} from '../GlobalLocations/GlobalLocations.actions';

const fetchGlobalLocationsLogic = createLogicWithApi({
  type: FETCH_GLOBAL_LOCATIONS,
  cancelType: CANCEL_FETCH_GLOBAL_LOCATIONS,
  latest: true,
  process: ({ Api }, dispatch, done) => {
    return Api({
      method: 'get',
      path: apiConfig.path,
      handle: r => {
        r
          .query({'filters': { 'disabled':'all' }})
          .query({'state': 'published'})
          .query({'limit': 0});
        return r;
      }
    })
      .then(resp => {
        dispatch(fetchGlobalLocationsSuccess(resp));
        done();
      })
      .catch(err => {
        dispatch(fetchGlobalLocationsError(err));
        done();
      });
  }
});

const logics = [
  fetchGlobalLocationsLogic
];

export default logics;
