import React from 'react';
import PropTypes from 'prop-types';

import withSvg from '../../hoc/withSvg';

const IconLoadingA = ({ fill, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 27.65 29.99"
    alt="Laster"
  >
    <g fill={fill ? fill : '#fff'} fillRule="evenodd">
      <polygon points="27.64 29.98 16.75 0 16.75 0 10.9 0 10.89 0 2.95 21.88 8.8 21.88 13.82 8.04 19.91 24.81 1.88 24.81 0 29.98 0 29.99 27.65 29.99 27.64 29.98" />
    </g>
  </svg>
);

IconLoadingA.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export default withSvg(IconLoadingA);
