import styled from 'styled-components';

const StyledHeader = styled.header`
  width: ${props => props.theme.menuSize}px;
  padding: 16px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;

  &::before {
    content: "";
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: ${props => props.theme.menuSize}px;
    height: 100%;
    background-color: ${props => props.theme.colorGrayDarkest};
  }

  ul {
    list-style: none;
  }

  ul,
  li {
    padding: 0;
    margin: 0;
  }

  .subtitle {
    font-family: ${props => props.theme.fonts.medium};
    color: ${props => props.theme.colorGray};
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin: 8px 16px;
  }

  hr {
    margin: 16px 8px;
    border: none;
    border-top: 1px solid ${props => props.theme.colorGrayDark};
  }

  .header-dropdown {
    display: block;

    .Select {
      width: auto;
      min-width: 150px;
    }

    .Select-placeholder {
      text-overflow: ellipsis;
      max-width: 180px;
      color: ${props => props.theme.colorGrayLight};
    }
  }
`;

export default StyledHeader;
