export const FETCH_LOCATIONS = '@@locations/FETCH_LOCATIONS';
export const FETCH_LOCATIONS_SUCCESS = '@@locations/FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_ERROR = '@@locations/FETCH_LOCATIONS_ERROR';
export const CANCEL_FETCH_LOCATIONS = '@@locations/CANCEL_FETCH_LOCATIONS';

export const RESET_LOCATION = '@@locations/RESET_LOCATION';
export const FETCH_LOCATION = '@@locations/FETCH_LOCATION';
export const FETCH_LOCATION_BY_SLUG = '@@locations/FETCH_LOCATION_BY_SLUG';
export const FETCH_LOCATION_SUCCESS = '@@locations/FETCH_LOCATION_SUCCESS';
export const FETCH_LOCATION_ERROR = '@@locations/FETCH_LOCATION_ERROR';
export const CANCEL_FETCH_LOCATION = '@@locations/CANCEL_FETCH_LOCATION';

export const SAVE_LOCATION = '@@locations/SAVE_LOCATION';
export const SAVE_LOCATION_SUCCESS = '@@locations/SAVE_LOCATION_SUCCESS';
export const SAVE_LOCATION_ERROR = '@@locations/SAVE_LOCATION_ERROR';
export const CANCEL_SAVE_LOCATION = '@@locations/CANCEL_SAVE_LOCATION';

export const REMOVE_LOCATION = '@@locations/REMOVE_RESERVATION';
export const REMOVE_LOCATION_SUCCESS = '@@locations/REMOVE_RESERVATION_SUCCESS';
export const REMOVE_LOCATION_ERROR = '@@locations/REMOVE_RESERVATION_ERROR';
export const CANCEL_REMOVE_LOCATION = '@@locations/CANCEL_REMOVE_RESERVATION';

export const SET_LOCATION_STATE = '@@locations/SET_LOCATION_STATE';
export const SET_LOCATION_STATE_SUCCESS = '@@locations/SET_LOCATION_STATE_SUCCESS';
export const SET_LOCATION_STATE_ERROR = '@@locations/SET_LOCATION_STATE_ERROR';
export const CANCEL_SET_LOCATION_STATE = '@@locations/CANCEL_SET_LOCATION_STATE';

export const SAVE_LOCATION_IMAGE = '@@locations/SAVE_LOCATION_IMAGE';
export const SAVE_LOCATION_IMAGE_SUCCESS = '@@locations/SAVE_LOCATION_IMAGE_SUCCESS';
export const SAVE_LOCATION_IMAGE_ERROR = '@@locations/SAVE_LOCATION_IMAGE_ERROR';
export const CANCEL_SAVE_LOCATION_IMAGE = '@@locations/CANCEL_SAVE_LOCATION_IMAGE';

export const DELETE_LOCATION_IMAGE = '@@locations/DELETE_LOCATION_IMAGE';
export const DELETE_LOCATION_IMAGE_SUCCESS = '@@locations/DELETE_LOCATION_IMAGE_SUCCESS';
export const DELETE_LOCATION_IMAGE_ERROR = '@@locations/DELETE_LOCATION_IMAGE_ERROR';
export const CANCEL_DELETE_LOCATION_IMAGE = '@@locations/CANCEL_DELETE_LOCATION_IMAGE';

export const PATCH_LOCATION_IMAGES = '@@locations/PATCH_LOCATION_IMAGES';
export const PATCH_LOCATION_IMAGES_SUCCESS = '@@locations/PATCH_LOCATION_IMAGES_SUCCESS';
export const PATCH_LOCATION_IMAGES_ERROR = '@@locations/PATCH_LOCATION_IMAGES_ERROR';
export const CANCEL_PATCH_LOCATION_IMAGES = '@@locations/CANCEL_PATCH_LOCATION_IMAGES';

export const REMOVE_GEOPOINT = '@@locations/REMOVE_GEOPOINT';
export const REMOVE_GEOPOINT_SUCCESS = '@@locations/REMOVE_GEOPOINT_SUCCESS';
export const REMOVE_GEOPOINT_ERROR = '@@locations/REMOVE_GEOPOINT_ERROR';
export const CANCEL_REMOVE_GEOPOINT = '@@locations/CANCEL_REMOVE_GEOPOINT';

export const TABLE_SELECT_LOCATION = '@@locations/TABLE_SELECT_LOCATION';

export function selectLocationTable(id) {
  return {
    type: TABLE_SELECT_LOCATION,
    id
  };
}

export function fetchLocations(config) {
  return {
    type: FETCH_LOCATIONS,
    ...config
  };
}

export function fetchLocationsSuccess(resp) {
  return {
    type: FETCH_LOCATIONS_SUCCESS,
    payload: resp
  };
}

export function fetchLocationsError(err) {
  return {
    type: FETCH_LOCATIONS_ERROR,
    payload: err
  };
}

export function resetLocation() {
  return {
    type: RESET_LOCATION
  };
}

export function fetchLocation(location) {
  return {
    type: FETCH_LOCATION,
    payload: location
  };
}

export function fetchLocationBySlug(slug) {
  return {
    type: FETCH_LOCATION_BY_SLUG,
    slug
  };
}

export function fetchLocationSuccess(resp) {
  return {
    type: FETCH_LOCATION_SUCCESS,
    payload: resp
  };
}

export function fetchLocationError(err) {
  return {
    type: FETCH_LOCATION_ERROR,
    payload: err
  };
}

export function saveLocation(data, nextStep) {
  return {
    type: SAVE_LOCATION,
    payload: data,
    nextStep
  };
}

export function saveLocationSuccess(resp, nextStep, id) {
  return {
    type: SAVE_LOCATION_SUCCESS,
    payload: resp,
    nextStep,
    id
  };
}

export function saveLocationError(id, err) {
  return {
    type: SAVE_LOCATION_ERROR,
    payload: err,
    id
  };
}

export function saveLocationImage(location, image) {
  return {
    type: SAVE_LOCATION_IMAGE,
    location,
    payload: image
  };
}

export function saveLocationImageSuccess(location, resp) {
  return {
    type: SAVE_LOCATION_IMAGE_SUCCESS,
    location,
    payload: resp
  };
}

export function saveLocationImageError(location, err) {
  return {
    type: SAVE_LOCATION_IMAGE_ERROR,
    location,
    payload: err
  };
}

export function deleteLocationImage(location, image) {
  return {
    type: DELETE_LOCATION_IMAGE,
    location,
    image
  };
}

export function deleteLocationImageSuccess(location, id) {
  return {
    type: DELETE_LOCATION_IMAGE_SUCCESS,
    location,
    id
  };
}

export function deleteLocationImageError(location, err) {
  return {
    type: DELETE_LOCATION_IMAGE_ERROR,
    location,
    payload: err
  };
}

export function patchLocationImages(location, images) {
  return {
    type: PATCH_LOCATION_IMAGES,
    location,
    images
  };
}

export function patchLocationImagesSuccess(location, images) {
  return {
    type: PATCH_LOCATION_IMAGES_SUCCESS,
    location,
    images
  };
}

export function patchLocationImagesError(location, err) {
  return {
    type: PATCH_LOCATION_IMAGES_ERROR,
    location,
    payload: err
  };
}

export const removeLocation = payload => ({
  type: REMOVE_LOCATION,
  ...payload
});

export const removeLocationSuccess = payload => ({
  type: REMOVE_LOCATION_SUCCESS,
  ...payload
});

export const removeLocationError = err => ({
  type: REMOVE_LOCATION_ERROR,
  payload: err
});

export const setLocationState = (id, enabled) => ({
  type: SET_LOCATION_STATE,
  id,
  enabled,
});

export const setLocationStateSuccess = (id, enabled) => ({
  type: SET_LOCATION_STATE_SUCCESS,
  id,
  enabled,
});

export const setLocationStateError = err => ({
  type: SET_LOCATION_STATE_ERROR,
  payload: err
});

export const removeGeopoint = id => ({
  type: REMOVE_GEOPOINT,
  id
});

export const removeGeopointSuccess = id => ({
  type: REMOVE_GEOPOINT_SUCCESS,
  id
});

export const removeGeopointError = err => ({
  type: REMOVE_GEOPOINT_ERROR,
  payload: err
});
