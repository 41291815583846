import styled, { css } from 'styled-components';

const required = css`
  &::after {
    color: ${props => props.theme.colorRed};
    content: '*';
  }
`;

const alignCenter = css`
    display: flex;
    align-items: center;
`;

const StyledLabel = styled.label`
  display: ${props => props.$inline ? 'inline-block' : 'block'};
  font-size: 12px;
  color: ${props => props.theme.colorGrayDarker};
  margin-top: 16px;
  margin-bottom: 6px;
  ${props => props.$nowrap ? 'white-space: nowrap;' : null};
  ${props => props.$required ? required : null};
  ${props => props.$alignCenter ? alignCenter : null};
`;

export default StyledLabel;
