import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import TableFilterSelect from '../Table/TableFilter/TableFilterSelect/TableFilterSelect.component';
import Logo from '../../../../shared/assets/logo.default';

import HeaderLink from './HeaderLink/HeaderLink.styled';
import HeaderTop from './HeaderTop/HeaderTop.styled';
import StyledHeader from './Header.styled';

const Header = ({
  adminLinks, 
  publicLinks, 
  externalLinks, 
  login, 
  onChangeSubMenu, 
  subMenu,
  email
}) => {
  if (!login?.loggedIn) {
    return null;
  }

  return (
    <StyledHeader>
      <HeaderTop>
        <Logo className="logo" />

        <TableFilterSelect 
          dark 
          alignLeft
          title={email}
          placeholder={email}
          className="header-dropdown"
          onChange={(item) => onChangeSubMenu(item) }
          options={subMenu} 
        />
      </HeaderTop>

      <nav>
        <ul>
          {publicLinks.map((link) => (
            <li key={link.key}>
              <HeaderLink {...link}>
                {link.name}
                {!!link.counter && (
                  <span className="counter">{link.counter}</span>
                )}
              </HeaderLink>
            </li>
          ))}
        </ul>
      </nav>

      {!adminLinks.length ? (
        null 
      ) : (
        <nav>
          <hr />
          
          <div className="subtitle">
            <FormattedMessage id="header.admin" />
          </div>

          <ul>
            {adminLinks.map((link) => (
              <li key={link.key}>
                <HeaderLink {...link}>
                  {link.name}
                  {!!link.counter && (
                    <span className="counter">{link.counter}</span>
                  )}
                </HeaderLink>
              </li>
            ))}
          </ul>
        </nav>
      )}

      {!externalLinks.length ? (
        null 

      ) : (
        <nav>
          <hr />
          
          <div className="subtitle">
            <FormattedMessage id="header.links" />
          </div>

          <ul>
            {externalLinks.map((link, index) => (
              <li key={index}>
                <HeaderLink 
                  to={link.path} 
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.name}
                  {!!link.counter && (
                    <span className="counter">{link.counter}</span>
                  )}
                </HeaderLink>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </StyledHeader>
  )
};

Header.propTypes = {
  onChangeSubMenu: PropTypes.func.isRequired,
  login: PropTypes.object,
  adminLinks: PropTypes.array,
  publicLinks: PropTypes.array,
  externalLinks: PropTypes.array,
  subMenu: PropTypes.array,
  email: PropTypes.string
};

export default Header;
